<template>
	<div class="text-center">
		<div class="row mb-4">
			<div class="col">
				<i
					:class="icon"
					class="fa fa-5x"
					aria-hidden="true" />
			</div>
		</div>
		<div class="row">
			<div class="col">
				<h3
					class="mb-3"
					style="color: #0553c7; font-weight: bold;">
					{{ translate(`${$options.filters.dashToUnderLine($route.params.module)}_${$route.params.type}`) }}
				</h3>
				<p
					class="mb-5 h5"
					style="color: #424242; font-weight: bold;">
					{{ translate(`${$options.filters.dashToUnderLine($route.params.module)}_${$route.params.type}_message`) }}
				</p>
				<div
					class="btn btn-primary"
					@click="$user.auth() ? $router.push({ name: 'Home' }) : $router.push({ name: 'Login' })">
					{{ $user.auth() ? translate('go_home') : translate('go_login') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import DocumentTitle from '@/mixins/DocumentTitle';
import { WHITELIST_TYPES, WHITELIST_MODULES, WHITELIST_CUSTOM_TYPES } from '@/settings/Alerts';
import {
	ERROR_ICON,
	SUCCESS_ICON,
	INFO_ICON,
	INVALID_ICON,
} from '@/settings/Icons';
import { Validations as messages } from '@/translations';

export default {
	name: 'EmailChanged',
	messages,
	filters: {
		dashToUnderLine: (text) => text.replace('-', '_'),
	},
	mixins: [DocumentTitle],
	data() {
		return {
			errorIcon: ERROR_ICON,
			successIcon: SUCCESS_ICON,
			infoIcon: INFO_ICON,
			invalidIcon: INVALID_ICON,
		};
	},
	beforeRouteUpdate: (to, from, next) => {
		const { type, module } = to.params;
		if (WHITELIST_MODULES.includes(module)) {
			if (WHITELIST_TYPES.includes(type) || (WHITELIST_CUSTOM_TYPES[module] !== undefined && WHITELIST_CUSTOM_TYPES[module].includes(type))) {
				return next();
			}
		}
		return next('/404');
	},
	computed: {
		icon() {
			const { type } = this.$route.params;
			return this[`${type}Icon`];
		},
	},
};
</script>
